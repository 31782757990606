import { template as template_cb90308a6c4f4d18b4fb74772fad2906 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_cb90308a6c4f4d18b4fb74772fad2906(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
