import { template as template_8879219a133a4c85be80b48048ecccc1 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_8879219a133a4c85be80b48048ecccc1(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
