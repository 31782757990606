import { template as template_4404e7a2fa424356932a922cb7357e28 } from "@ember/template-compiler";
const WelcomeHeader = template_4404e7a2fa424356932a922cb7357e28(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
